<template>
  <div class="max-w-5xl h-full mx-auto mt-4 text-center">
    <img class="h-10 mx-auto" src="../assets/img/racla-logo.png" alt="" />
    <div class="relative mt-24">
      <h2
        class="
          absolute
          text-2xl
          left-24
          font-bold
          transform
          z-10
          -rotate-20
          text-white
          e
        "
        style="left: 50%; top: 33%"
      >
        Heyyy
      </h2>
      <img
        class="relative h-80 mx-auto"
        src="../assets/img/welcome.svg"
        alt="welcome image"
      />
    </div>
    <h2 class="text-4xl font-bold mt-8">Connect with us</h2>
    <div class="flex h-8 align-middle justify-around max-w-xs mx-auto mt-5">
      <a href="https://www.facebook.com/RACLangata">
        <img
          class="h-8"
          src="../assets/img/facebook-logo.png"
          alt="Rotaract Lang'ata facebook page"
          target="_blank"
      /></a>
      <a href="https://www.instagram.com/rotaractlangata/">
        <img
          class="h-8"
          src="../assets/img/instagram-logo.png"
          alt="Rotaract Lang'ata instagram page"
          target="_blank"
      /></a>
      <a href="https://twitter.com/RotaractLangata">
        <img
          class="h-8"
          src="../assets/img/twitter.png"
          alt="Rotaract Lang'ata twitter page"
          target="_blank"
      /></a>
      <a href="https://www.linkedin.com/company/rotaract-club-of-langata/">
        <img
          class="h-8"
          src="../assets/img/linkedin.png"
          alt="Rotaract Lang'ata linkedin page"
          target="_blank"
      /></a>
    </div>
    <p class="mt-28 text-sm text-pink-600">Site under construction</p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {},
}
</script>
